import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/danismanlik-company"
import rightArrow from "../assets/img/right-arrow.png";

import marka from "../assets/img/yenigifler.gif"
import surec1 from "../assets/img/button1.png"
import surec2 from "../assets/img/button2.png"
import smgif from "../assets/img/smgif.gif"
import filtregif from "../assets/img/filtregif.gif"
import nkl from "../assets/img/nklnew.png"
import arfiltre from "../assets/img/ar-filtre-head.png"
import work from "../assets/img/work-preformance.png"
import Checkup from "../components/checkup"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/web-company"
import stm from "../assets/img/companys/22.png"
import logo from "../assets/img/logo.png"


class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');

        document.querySelector('#site').innerHTML = this.state.value;
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Success!";
            }
            else {
                document.querySelector('.status').innerHTML = "Error";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="AR Filter"
                 description="We design custom augmented reality filters for your brand. With our interactive AR filters, we help you increase your brand awareness." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>AR Filter </h1>
                            <span className="red-head">SERVICES</span>
                            <p style={{ maxWidth: 450 }} className="arfiltre" > We design AR filters to maximize your online sales, brand awareness and engage with social media users in a fun way.</p>
                            <input type="text" className="pink-input" id="email-marketing-input" placeholder="Enter your e-mail address" value={this.state.value} onChange={this.handleChange} />
                                    <button className="red-button" id="email-marketing-button" onClick={(e) => this.showModal()}>Get your special offer</button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 33.5, paddingLeft: 50 }}><img alt="alt text" src={arfiltre} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Fill the form</h2>                               <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                            <div className="check-modal_container">
                            <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Enter your website." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter yor email" />
                                    <button  >SEND</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="container brands_content">
                    <h2 style={{ color: '#ea4f6a' }}>
                    What is AR Filter? 
                    </h2>
                    <p className="arfiltre">AR, or Augmented Reality, is a new technological development that has emerged in recent years. AR filter is defined as the addition of virtual objects on real object images thanks to the activation of the object recognition function of the devices.</p>

                </div>

                <div className="brands_content">
                    <h2 style={{ fontWeight: 700 }}>
                    Some of our upcoming works.
                        <svg className="comment-icon" id="Group_817" data-name="Group 817" xmlns="http://www.w3.org/2000/svg" width="50.007" height="55.09" viewBox="0 0 87.007 85.09">
                            <g id="Group_813" data-name="Group 813" transform="translate(0 0)">
                                <path id="Path_23741" data-name="Path 23741" d="M70.6,0H16.409A16.409,16.409,0,0,0,0,16.409V46.938A16.409,16.409,0,0,0,16.409,63.347h5.263a4.458,4.458,0,0,1,3.316,1.478l17.223,19.16a3.336,3.336,0,0,0,5.034-.083L63.2,64.935a4.459,4.459,0,0,1,3.412-1.588H70.6A16.409,16.409,0,0,0,87.007,46.938V16.409A16.41,16.41,0,0,0,70.6,0Z" transform="translate(0 0)" fill="#ed4a57" />
                            </g>
                            <g id="Group_816" data-name="Group 816" transform="translate(17.681 19.866)">
                                <g id="Group_814" data-name="Group 814">
                                    <path id="Path_23742" data-name="Path 23742" d="M311.748,326.479a7.388,7.388,0,0,0-13.507-3.646.183.183,0,0,1-.306,0A7.387,7.387,0,1,0,287.4,332.884l9.87,9.095a.991.991,0,0,0,1.333.009l10-8.984A7.379,7.379,0,0,0,311.748,326.479Z" transform="translate(-284.414 -319.562)" fill="#fff" />
                                </g>
                                <g id="Group_815" data-name="Group 815" transform="translate(39.121 1.067)">
                                    <path id="Path_23743" data-name="Path 23743" d="M918.009,339.391h-.065l-3.68,1.987-.553-2.182,4.625-2.475h2.443v21.17h-2.768Z" transform="translate(-913.71 -336.72)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </h2>
                    <img className="gifImage" style={{ marginTop: 50 }} alt="alt text" src={marka}></img>

                </div>
                <div className="brands_content">
                    <img className="gifImageNew" style={{ marginTop:-60 }} alt="alt text" src={nkl}></img>
                </div>
                
               <div className="container brands_content">
                    <h2 style={{ color: '#ea4f6a' }}>
                    AR Filter Production Process

                    </h2>
                </div>
                <div className="onboarding container py60">

                    <div className="col-md-6">
                        <p className="arfiltre">One of the fun and economical ways to maximize your online sales, brand awareness and communicate with users during special occasions, campaign periods, and events is to use AR filters. You can organically feed your brand awareness through filters that are frequently used by social media users. Thanks to our AR filter service, which is designed in the way you want and open to user interaction, fiction, and scenario work is done in accordance with your brand identity.</p>
                    </div>
                    <div className="col-md-6 filtreImage">
                        <img style={{ minWidth: 500 }} alt="alt text" src={surec1}></img>
                    </div>

                </div>
                <div className="onboarding container">
                    <div className="col-md-6 filtreImage">
                        <img style={{ minWidth: 500 }} alt="alt text" src={surec2}></img>
                    </div>
                    <div className="col-md-6">
                        <p className="arfiltre">In line with the created AR filter fiction, we prepare a catchy and remarkable design and make it ready for publication on the platform where it will be used. All AR filters we have designed contain highly original and striking details. Therefore, each of them is entirely catchy and interesting.
                        </p>
                    </div>

                </div>
             
                <div className="container py60">
                    <div className="col-md-2">

                    </div>
                    <div className="col-md-4" >
                        <img alt="alt text" style={{ marginLeft:-30}}  src={filtregif}></img>
                    </div>
                    <div className="col-md-4">
                        <img alt="alt text" src={smgif}></img>
                    </div>
                    <div className="col-md-2">

                    </div>

                </div>
               
                <div className="container brands_content">
                <h2 style={{ color: '#ea4f6a' }}>
                Advantages of Using AR Filters
                    </h2>
                    <div className="list-container">
                      <p className="list-item">• Interaction: AR filters allow consumers to interact with a brand in a fun and engaging way, increasing brand awareness. </p>
                      <p className="list-item">• Affordability: AR filters can be created at a relatively low cost and used across social media platforms. This makes it a cost-effective marketing tool.</p>
                      <p className="list-item">• Measurability: The measurability of AR filters makes it easy to evaluate the effectiveness of the campaigns.</p>
                      <p className="list-item">• Mobile Compatibility: Being compatible with mobile devices makes AR filters a suitable marketing tool for reaching a wide audience.</p>
                    </div>
                </div>

                <Checkup />





            </Layout>
        )
    }
}
export default SocialMedia
