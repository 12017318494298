import { Link } from "gatsby"
import React from "react"

import arcelik from "../assets/img/companys/21.png"
import tanoto from "../assets/img/companys/26.png"
import mocaco from "../assets/img/companys/47.png"
import maabir from "../assets/img/companys/48.png"
import nills from "../assets/img/companys/35.png"
import stm from "../assets/img/companys/22.png"





const CompanysSEO = () => (
    <div className="company-component">
        <div className="company-box">
            <img src={tanoto} />
        </div>
        <div className="company-box">
            <img src={mocaco} />
        </div>
        <div className="company-box">
            <img src={stm} />
        </div>
        <div className="company-box">
            <img src={nills} />
        </div>
        <div className="company-box">
            <img src={maabir} />
        </div>
        <div className="company-box">
            <img src={arcelik} />
        </div>    
        
    </div>
)

export default CompanysSEO
